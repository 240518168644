import React, { useState, useEffect } from 'react'
import { getTreePDF } from '../utils/restAPI'
import { Link } from '@material-ui/core'
import { TreeView, TreeItem } from '@material-ui/lab/'
import {
	//ExpandMore,
	//ChevronRight,
	PictureAsPdfOutlined,
	Folder,
	FolderOpen,
} from '@material-ui/icons/'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
	},
	treeRoot: {
		marginTop: 15,
		marginLeft: 15,
		//height: '100%',
		flexGrow: 1,
		maxWidth: 600,
	},
	icon: {
		color: theme.palette.secondary.main,
	},
	folder: {
		//color: theme.palette.secondary.main
		//color: '#ffffff'
		//fontSize: '2em'
	},
}))

function Pdf() {
	const classes = useStyles()
	const [pdfTree, setPdfTree] = useState(null)
	const [expanded, setExpanded] = React.useState([])
	const [selected, setSelected] = React.useState([])

	useEffect(() => {
		getTreePDF.get('').then(res => {
			setPdfTree(res.data)
		})
	}, [])

	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds)
	}

	const handleSelect = (event, nodeIds) => {
		setSelected(nodeIds)
	}

	return (
		<div className={classes.root}>
			<TreeView
				className={classes.treeRoot}
				//			defaultCollapseIcon={<ExpandMore />}
				//		defaultExpandIcon={<ChevronRight />}
				defaultCollapseIcon={<FolderOpen className={classes.icon} />}
				defaultExpandIcon={<Folder className={classes.icon} />}
				expanded={expanded}
				selected={selected}
				onNodeToggle={handleToggle}
				onNodeSelect={handleSelect}
			>
				{pdfTree &&
					pdfTree.map((folder, i) => {
						return (
							<TreeItem
								key={i}
								nodeId={`folder${i}`}
								label={folder.name}
								classes={{ label: classes.folder }}
							>
								{folder.files.map((item, j) => {
									return (
										<TreeItem
											key={`folder${i}-file${j}`}
											nodeId={`folder${i}-file${j}`}
											icon={<PictureAsPdfOutlined className={classes.icon} />}
											label={
												<Link
													key={`folder${i}-file${j}`}
													target="_blank"
													rel="noreferrer"
													color="inherit"
													href={`${folder.path}/${item.file}`}
												>
													{item.file}
												</Link>
											}
										/>
									)
								})}
							</TreeItem>
						)
					})}
			</TreeView>
		</div>
	)
}

export default Pdf
