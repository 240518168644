import React, { useState, createContext } from 'react'

const ConfigContext = createContext()

const defaultConfig = null

export const ConfigProvider = ({ children, config }) => {
	const [currentConfig, setCurrentConfig] = useState(config || defaultConfig)

	const saveConfig = values => {
		setCurrentConfig(values)
	}

	return (
		<ConfigContext.Provider value={{ config: currentConfig, saveConfig }}>
			{children}
		</ConfigContext.Provider>
	)
}

//export const ConfigConsumer = ConfigContext.Consumer

export default ConfigContext
