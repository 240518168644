const moment = require('moment')

export const msToTime = s => {
	function addZ(n) {
		return (n < 10 ? '0' : '') + n
	}

	const ms = s % 1000
	s = (s - ms) / 1000
	const secs = s % 60
	s = (s - secs) / 60
	const mins = s % 60
	const hrs = (s - mins) / 60

	if (ms === 0) {
		return addZ(hrs) + ':' + addZ(mins) + ':' + addZ(secs)
	} else {
		return addZ(hrs) + ':' + addZ(mins) + ':' + addZ(secs) + '.' + ms / 100
	}
}

export const msToSeconds = s => {
	const result = s / 1000
	return Math.round(result)
}

export const msToMinutesCH = s => {
	let result
	const seconds = s / 1000
	const minutes = s / 1000 / 60
	if (seconds < 60 && seconds >= 0) {
		result = 0
	} else {
		result = minutes
	}
	return result
}

export const msToSecondsRT = s => {
	const result = s / 1000
	return result
}

export const timeToMs = time => {
	let string = ''
	let hours = 0
	let minutes = 0
	let seconds = 0
	let milliseconds = 0
	string = time
	const date = new Date(0)
	const timeTemp = string.split(':')
	if (timeTemp.length === 3) {
		hours = timeTemp[0]
		minutes = timeTemp[1]
		seconds = timeTemp[2]
	} else if (timeTemp.length === 2) {
		hours = 0
		minutes = timeTemp[0]
		seconds = timeTemp[1]
	}

	if (seconds.indexOf('.') !== -1) {
		const arrayMilli = seconds.split('.')
		seconds = arrayMilli[0]
		milliseconds = arrayMilli[1] * 100
	}
	date.setUTCHours(hours, minutes, seconds, milliseconds)
	return date.getTime()
}

export const secondsToMs = time => {
	return time * 1000
}

export const pointsToTime = (points, isDecimal = false, type) => {
	const timePattern = isDecimal ? 'HH:mm:ss.S' : 'HH:mm:ss'
	let result = !points ? '' : moment.utc((points * 10000) / 10).format(timePattern)
	if (points * 1 < 3600) result = result.substring(3)
	return result
}
