import axios from 'axios'

const rallyCode = process.env.REACT_APP_RALLY_CODE.toLowerCase()
const uri =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:4100'
		: `https://${rallyCode}-server.vercel.app`

export const getTreePDF = axios.create({
	baseURL: `${uri}/getTreePDF`,
})
