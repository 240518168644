import React from 'react'
import { FormControl, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		width: 60,
	},
	selectEmpty: {
		//marginTop: theme.spacing(2),
	},
}))

function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
	const classes = useStyles()
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = React.useMemo(() => {
		const options = new Set()
		preFilteredRows.forEach(row => {
			options.add(row.values[id])
		})
		return [...options.values()]
	}, [id, preFilteredRows])

	// Render a multi-select box
	return (
		<FormControl className={classes.formControl}>
			<Select
				value={filterValue || 'reset'}
				onChange={e => {
					setFilter(e.target.value || undefined)
				}}
				className={classes.selectEmpty}
				//inputProps={{ 'aria-label': 'Without label' }}
			>
				<MenuItem value="reset">
					<em>All</em>
				</MenuItem>
				{options.map((option, i) => (
					<MenuItem key={i} value={option}>
						{option}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

export default SelectColumnFilter
