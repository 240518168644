import React, { createContext } from 'react'
import { useStateWithLocalStorage } from '../hooks'

const OptionsContext = createContext()

export const OptionsProvider = ({ children, races }) => {
	const [carNumber, setCarNumber] = useStateWithLocalStorage('carNumber', '')

	return (
		<OptionsContext.Provider value={{ carNumber, setCarNumber }}>
			{children}
		</OptionsContext.Provider>
	)
}

//export const RaceConsumer = RaceContext.Consumer

export default OptionsContext
