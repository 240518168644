export const filterRTWithPosition = (rows, id, filterValue) => {
	if (filterValue !== 'reset') {
		return rows
			.filter(row => {
				const rowValue = row.values[id]
				return rowValue.toString() === filterValue.toString()
			})
			.map((row, i, mapRows) => {
				row.values['position'] = i + 1
				const differencePrevious = i > 0 ? row.original.total - mapRows[i - 1].original.total : null
				row.values['difPrevious'] =
					differencePrevious === null
						? null
						: differencePrevious === 0
						? '='
						: '+' + differencePrevious.toFixed(Number.isInteger(differencePrevious) ? 0 : 2) //TODO: add isTimeTotal && isDecimal
				const firstTotal = i > 0 ? mapRows[0].original.total : 0
				const currentTotal = row.original.total
				const differenceFirst = i > 0 ? currentTotal - firstTotal : null
				row.values['difFirst'] =
					differenceFirst === null
						? null
						: '+' + differenceFirst.toFixed(Number.isInteger(differenceFirst) ? 0 : 2) //TODO: add isTimeTotal && isDecimal
				return row
			})
	} else {
		return rows.map((row, i) => {
			row.values['position'] = row.original.position
			row.values['difPrevious'] = row.original.difPrevious
			row.values['difFirst'] = row.original.difFirst
			return row
		})
	}
}
export const filterOVWithPosition = (rows, id, filterValue) => {
	if (filterValue !== 'reset') {
		return rows
			.filter(row => {
				const rowValue = row.values[id]
				return rowValue.toString() === filterValue.toString()
			})
			.map((row, i, mapRows) => {
				row.values['position'] = i + 1
				const differencePrevious =
					i > 0 ? row.original.totalOV - mapRows[i - 1].original.totalOV : null
				row.values['difPrevious'] =
					differencePrevious === null
						? null
						: differencePrevious === 0
						? '='
						: '+' + differencePrevious.toFixed(Number.isInteger(differencePrevious) ? 0 : 2) //TODO: add isTimeTotal && isDecimal
				const firstTotal = i > 0 ? mapRows[0].original.totalOV : 0
				const currentTotal = row.original.totalOV
				const differenceFirst = i > 0 ? currentTotal - firstTotal : null
				row.values['difFirst'] =
					differenceFirst === null
						? null
						: '+' + differenceFirst.toFixed(Number.isInteger(differenceFirst) ? 0 : 2) //TODO: add isTimeTotal && isDecimal
				return row
			})
	} else {
		return rows.map((row, i) => {
			row.values['position'] = row.original.position
			row.values['difPrevious'] = row.original.difPrevious
			row.values['difFirst'] = row.original.difFirst
			return row
		})
	}
}
