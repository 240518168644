import React from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useConfig } from '../../hooks'

const FooterRT = ({ viewTypeRT, setViewTypeRT, rtPrefix }) => {
	const { config } = useConfig()
	const intl = useIntl()

	return (
		<Tabs
			value={viewTypeRT}
			onChange={(event, value) => {
				if (value !== null) setViewTypeRT(value)
			}}
			indicatorColor="secondary"
			//textColor="primary"
			variant="fullWidth"
			aria-label="full width tabs example"
		>
			<Tab label={`${rtPrefix} ${intl.formatMessage({ id: 'summary' })}`} value="summary" />
			<Tab label={`${rtPrefix} ${intl.formatMessage({ id: 'detailed' })}`} value="detailed" />
			{config.isPartialOV && (
				<Tab label={`${intl.formatMessage({ id: 'overall' })}`} value="rankingRTOV" />
			)}
		</Tabs>
	)
}

FooterRT.propTypes = {}
FooterRT.defaultProps = {}

export default FooterRT
